import React, { useContext } from "react";
import {
  PSelect,
  PSelectOption,
} from "@porsche-design-system/components-react";
import { LanguageContext } from "MainApp/assets/utils/context/LanguageContext";
import { IsRotatedContext } from "MainApp/assets/utils/context/isRotatedContext";
import { useTranslation } from "react-i18next";
import "./LanguageSelector.css";

const LanguageSelector = ({ className, isPhone }) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const { isRotated } = useContext(IsRotatedContext);
  const { i18n } = useTranslation();

  const handleChange = (e) => {
    const newLanguage = e.target.value;
    i18n.changeLanguage(newLanguage);
  };

  function simplifyLanguageCode(languageCode) {
    if (typeof languageCode !== "string") {
      throw new Error("El código de idioma debe ser una cadena.");
    }

    // Divide el código por el guion ('-') y toma la primera parte
    const simplifiedCode = languageCode.split("-")[0];

    return simplifiedCode;
  }

  return (
    <>
      {/* Selector para dispositivos móviles */}
      <select
        name="language"
        className={className ? className : "languageSelector"}
        onChange={handleChange}
        value={simplifyLanguageCode(i18n.language)}
        style={{ padding: isPhone ? (isRotated ? "" : "") : 4 }}
      >
        <option value="en">English</option>
        <option value="es">Español</option>
        <option value="fr">Français</option>
        {/* <option value="test">{simplifyLanguageCode(i18n.language)}</option> */}
      </select>

      {/* Selector para otros dispositivos (descomentado si lo necesitas) */}
      {/*
                !isPhone && (
                    <PSelect
                        name="options"
                        value={language}
                        onUpdate={handleChange}
                        className={className ? className : "languageSelector"}
                    >
                        <PSelectOption value="es">Español</PSelectOption>
                        <PSelectOption value="en">English</PSelectOption>
                        <PSelectOption value="fr">Français</PSelectOption>
                    </PSelect>
                )
            */}
    </>
  );
};

export default LanguageSelector;
