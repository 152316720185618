// src/App.js
import React from 'react';
import './Son.css';

function Son() {
    const enviarMensaje = () => {
        // Enviar un mensaje a la ventana padre
        window.parent.postMessage('cambiarFondo', '*');
    };

    return (
        <div className="App">
            <h2>Web Ejemplo 2</h2>
            <button onClick={enviarMensaje}>Cambiar Color de Fondo</button>
        </div>
    );
}

export default Son;
