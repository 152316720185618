import React, { useCallback, useContext, useEffect, useState } from "react";
import SmoothSlider from "Components/SmoothSlider/SmoothSlider";
import "./InformationDescriptionSide.css";

import { Mail, Phone } from "lucide-react";
import { motion } from "framer-motion";
import {
  PAccordion,
  PDivider,
  PTable,
  PTableBody,
  PTableCell,
  PTableRow,
} from "@porsche-design-system/components-react";
import axiosInstance from "axiosConfig";
import { useTranslation } from "react-i18next";

import VitalInfoComponent from "Components/VitalInfoComponent/VitalInfoComponent";
import CallToBook from "Components/CallToBook/CallToBook";
import {
  spacingFluidLarge,
  spacingFluidMedium,
  spacingFluidSmall,
  spacingFluidXSmall,
} from "@porsche-design-system/components-js/styles";
import { ToursContext } from "MainApp/assets/utils/context/TourContext";
import { SkeletonImagePreload } from "MainApp/assets/visual/styles/globalStyles/globalJsxStyles";

const InformationDescriptionSide = ({ tour, isPhone }) => {
  const { t } = useTranslation("tourInfo");
  const { getImagesByTourId, setImagesByTourId } = useContext(ToursContext);
  const [images, setImages] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const onUpdate1 = useCallback((e) => {
    setIsOpen1(e.detail.open);
  }, []);

  const onUpdate2 = useCallback((e) => {
    setIsOpen2(e.detail.open);
  }, []);

  const onUpdate3 = useCallback((e) => {
    setIsOpen3(e.detail.open);
  }, []);

  const onUpdate4 = useCallback((e) => {
    setIsOpen4(e.detail.open);
  }, []);

  useEffect(() => {
    if (tour) {
      fetchImages();
      fetchLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tour]);
  useEffect(() => {
    const cachedImages = getImagesByTourId(tour.id);
    if (cachedImages) {
      setImages(cachedImages);
    } else {
      fetchImages();
    }
  }, [tour.id]);
  const fetchImages = async () => {
    try {
      const response = await axiosInstance.get("/api/toursAPI/get-images", {
        params: { UUID: tour.id },
      });
      setImages(response.data);
      setImagesByTourId(tour.id, response.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const fetchLocations = async () => {
    const params = { tourUUID: tour.id };
    try {
      const response = await axiosInstance.get("/api/toursAPI/get-locations", {
        params,
      });
      setLocations(response.data);
    } catch (error) {
      console.log("response ERROR Locations");
    }
  };

  if (!tour) return null;

  const rotatedStyle = {
    // minHeight: isRotated ? "" : "40svh",
    minHeight: "40svh",
  };

  // Obtener los contenidos de traducción como objetos
  const infoContent =
    t("accordion.info.content", { returnObjects: true }) || {};
  const legalContent =
    t("accordion.legal.content", { returnObjects: true }) || {};
  const rotatedStyleSkeleton = {
    maxHeight: "40svh",
    width: "90%",
  };
  const custom = {
    height: "100%",
    position: "absolute",
    zIndex: "200",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  };
  return (
    <div
      className="informationDescriptionSide"
      style={{ gap: isPhone ? "" : spacingFluidSmall }}
    >
      {isPhone && <CallToBook isSimple={true} isPhone={isPhone} tour={tour} />}

      <div
        className="infoSliderContainer"
        style={{
          marginTop: isPhone ? spacingFluidLarge : "",
          marginBottom: isPhone ? spacingFluidSmall : "",
          marginLeft: isPhone ? spacingFluidSmall : spacingFluidMedium,
          marginRight: isPhone ? spacingFluidSmall : spacingFluidMedium,
          ...rotatedStyle,
        }}
      >
        {!imageLoaded && (
          <SkeletonImagePreload
            style={{
              ...rotatedStyleSkeleton,
              ...custom,
            }}
          />
        )}
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="smoothContainerInfoSide"
          style={{ rotatedStyle }}
        >
          <SmoothSlider
            images={images}
            imageLoaded={imageLoaded}
            setImageLoaded={setImageLoaded}
          />
        </motion.div>
      </div>

      <PDivider direction={"vertical"} className="vDivider" />

      <div
        className="infoArea"
        style={{ paddingTop: isPhone ? spacingFluidSmall : "" }}
      >
        <VitalInfoComponent tour={tour} isMulti={true} />

        <PDivider
          className="hDivider"
          style={{
            marginTop: isPhone ? spacingFluidSmall : "",
            marginBottom: isPhone ? spacingFluidXSmall : "",
          }}
        />

        {/* Accordion de Localizaciones */}
        <PAccordion
          heading={t("accordion.loc.head") || "Loading..."}
          open={isOpen1}
          onUpdate={onUpdate1}
        >
          <PTable caption={t("accordion.loc.caption") || "Loading..."}>
            <PTableBody>
              {locations.map((location, index) => (
                <PTableRow key={location}>
                  <PTableCell>{location.name}</PTableCell>
                </PTableRow>
              ))}
            </PTableBody>
          </PTable>
        </PAccordion>

        {/* Accordion de Información Importante */}
        <PAccordion
          heading={t("accordion.info.head") || "Loading..."}
          open={isOpen2}
          onUpdate={onUpdate2}
        >
          <PTable caption={t("accordion.info.caption") || "Loading..."}>
            <PTableBody>
              {Object.keys(infoContent).map((key, index) => (
                <PTableRow key={key}>
                  <PTableCell multiline={true}>
                    {infoContent[key] || "Loading..."}
                  </PTableCell>
                </PTableRow>
              ))}
            </PTableBody>
          </PTable>
        </PAccordion>

        {/* Accordion de Política de Cancelación */}
        <PAccordion
          heading={t("accordion.legal.head") || "Loading..."}
          open={isOpen3}
          onUpdate={onUpdate3}
        >
          <PTable caption={t("accordion.legal.caption") || "Loading..."}>
            <PTableBody>
              {Object.keys(legalContent).map((key, index) => (
                <PTableRow key={key}>
                  <PTableCell multiline={true}>
                    {legalContent[key] || "Loading..."}
                  </PTableCell>
                </PTableRow>
              ))}
            </PTableBody>
          </PTable>
        </PAccordion>

        {/* Accordion de Información Adicional */}
        <PAccordion
          heading={t("accordion.further.head") || "Loading..."}
          open={isOpen4}
          onUpdate={onUpdate4}
        >
          <PTable caption={t("accordion.further.caption") || "Loading..."}>
            <PTableBody>
              <PTableRow>
                <PTableCell multiline={false}>
                  <p className="text-sm text-gray-700">
                    {t("accordion.further.content") || "Loading..."}
                  </p>
                </PTableCell>
              </PTableRow>
              <PTableRow>
                <PTableCell>
                  <div className="flex items-center space-x-3">
                    <Phone className="h-5 w-5 text-gray-500" />
                    &nbsp;
                    <a
                      href={`https://wa.me/${process.env.REACT_APP_PHONE1}`}
                      className="text-sm text-gray-600 hover:text-gray-900"
                    >
                      {process.env.REACT_APP_PHONE1}
                    </a>
                  </div>
                </PTableCell>
              </PTableRow>
              <PTableRow>
                <PTableCell>
                  <div className="flex items-center space-x-3">
                    <Mail className="h-5 w-5 text-gray-500" />
                    &nbsp;
                    <a
                      href={`mailto:${process.env.REACT_APP_EMAIL}`}
                      className="text-sm text-gray-600 hover:text-gray-900"
                    >
                      {process.env.REACT_APP_EMAIL}
                    </a>
                  </div>
                </PTableCell>
              </PTableRow>
            </PTableBody>
          </PTable>
        </PAccordion>
      </div>
    </div>
  );
};

export default InformationDescriptionSide;
