import axiosInstance from "axiosConfig";
import React, { createContext, useState, useCallback, useEffect } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const { i18n, ready } = useTranslation();
  const [language, setLanguageState] = useState(i18n.language);
  // const [language, setLanguage] = useState('es');
  useEffect(() => {
    if (ready) {
      setLanguageState(i18n.language);
    }
  }, [ready, i18n.language]);

  const setLanguage = (lng) => {
    i18next.changeLanguage(lng);
    setLanguageState(lng);
  };

  const [textsCache, setTextsCache] = useState(() => {
    const savedCache = localStorage.getItem("textsCache");
    return savedCache ? JSON.parse(savedCache) : {};
  });

  const fetchTexts = useCallback(
    async (lang, page) => {
      const cacheKey = `${lang}_${page}`;
      const now = Date.now();
      const cacheTTL = 5 * 60 * 1000; // 5 minutos

      // Verificar si la caché está aún válida
      const cachedData = textsCache[cacheKey];
      if (cachedData && now - cachedData.timestamp < cacheTTL) {
        return cachedData.data;
      } else {
        try {
          const response = await axiosInstance.get(
            `/api/texts/${lang}/${page}`
          );
          const data = response.data;

          const cleanedData = Object.keys(data).reduce((acc, key) => {
            acc[key] =
              typeof data[key] === "string"
                ? data[key].replace(/^"(.*)"$/, "$1")
                : data[key];
            return acc;
          }, {});

          const newCache = {
            ...textsCache,
            [cacheKey]: {
              timestamp: now,
              data: cleanedData,
            },
          };

          setTextsCache(newCache);
          localStorage.setItem("textsCache", JSON.stringify(newCache));

          return cleanedData;
        } catch (error) {
          console.error("Error fetching texts:", error);
          return null;
        }
      }
    },
    [textsCache]
  );

  // Escuchar cambios en textsCache y notificar
  useEffect(() => {
    const handleCacheChange = () => {
      localStorage.setItem("textsCache", JSON.stringify(textsCache));
    };

    window.addEventListener("storage", handleCacheChange);

    return () => {
      window.removeEventListener("storage", handleCacheChange);
    };
  }, [textsCache]);

  if (!ready) {
    return null; // O puedes mostrar un indicador de carga
  }
  return (
    <LanguageContext.Provider value={{ language, setLanguage, fetchTexts }}>
      {children}
    </LanguageContext.Provider>
  );
};
