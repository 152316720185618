// src/components/TourContainer.js
import React, { useState, useEffect, useRef, useContext } from "react";
import { ReactComponent as Logo } from "MainApp/assets/visual/vectors/UI/logos/SkLogo.svg";
// import { ReactComponent as Logo } from 'MainApp/assets/visual/vectors/UI/logos/NewLogo.svg';
import "./TourContainer.css";
import {
  PButtonPure,
  PModal,
  PDivider,
} from "@porsche-design-system/components-react";
import LanguageSelector from "Components/LanguageSelector/LanguageSelector";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DefaultTitleMedia,
  HeadSelect,
  insertFadeInKeyframes,
  TitleMedia,
} from "MainApp/assets/visual/styles/globalStyles/globalJsxStyles";
import ClosingButton from "Components/ClosingButton/ClosingButton";
import { ClosingModal } from "Components/ClosingModal/ClosingModal";
import BorderedContainer from "Components/BorderedContainer/BorderedContainer";
import FooterPoweredBy from "Components/FooterPoweredBy/FooterPoweredBy";
import useIsPhone from "MainApp/assets/utils/hooks/useIsPhone";
import { IsRotatedContext } from "MainApp/assets/utils/context/isRotatedContext";
import {
  borderRadiusLarge,
  frostedGlassStyle,
  spacingFluidMedium,
  spacingFluidXSmall,
  themeDarkPrimary,
  themeLightStateHover,
} from "@porsche-design-system/components-js/styles";
import { useTranslation } from "react-i18next";

const TourContainer = ({ children, screen }) => {
  const { isRotated } = useContext(IsRotatedContext);
  const { t } = useTranslation("tourContainer");
  const navigate = useNavigate();
  const location = useLocation();
  const tour = location.state;

  const isPhone = useIsPhone();
  const [isModalReady, setIsModalReady] = useState(false);
  const [bgLogoStyle, setBgLogoStyle] = useState({ width: 0, height: 0 });
  const [isLogoReady, setIsLogoReady] = useState(false); // Controla si el Logo está listo
  const [shouldCheck, setShouldCheck] = useState(true); // Controla la comprobación constante
  const modalRef = useRef(null);
  const logoRef = useRef(null);
  const [titleTextStyling, setTitleTextStyling] = useState({});

  useEffect(() => {
    const checkLogoDimensions = () => {
      if (logoRef.current) {
        const logoData = logoRef.current.getBoundingClientRect();
        if (logoData.width > 0 && logoData.height > 0) {
          setTitleTextStyling({ right: logoData.width * 3.5 });
          setBgLogoStyle({
            width: logoData.width * 3,
            height: logoData.width * 3,
          });
          setIsLogoReady(true); // Marcar el Logo como listo cuando tiene dimensiones válidas
          setShouldCheck(false); // Desactivar la comprobación constante
        }
      }
    };

    if (shouldCheck) {
      const interval = setInterval(checkLogoDimensions, 50); // Comprobación cada 50ms
      return () => clearInterval(interval);
    }
  }, [shouldCheck]);

  useEffect(() => {
    if (isLogoReady) {
      const updateLogoStyles = () => {
        if (logoRef.current) {
          const logoData = logoRef.current.getBoundingClientRect();
          setTitleTextStyling({ right: logoData.width * 3.5 });
          setBgLogoStyle({
            width: logoData.width * 3,
            height: logoData.width * 3,
          });
        }
      };

      // Usar ResizeObserver para observar cambios en las dimensiones del logo
      const resizeObserver = new ResizeObserver(updateLogoStyles);

      if (logoRef.current) {
        resizeObserver.observe(logoRef.current);
      }

      // Escuchar el evento de cambio de tamaño de la ventana
      const handleWindowResize = updateLogoStyles;
      window.addEventListener("resize", handleWindowResize);

      // Cleanup
      return () => {
        if (logoRef.current) {
          resizeObserver.unobserve(logoRef.current);
          resizeObserver.disconnect();
        }
        window.removeEventListener("resize", handleWindowResize);
      };
    }
  }, [isLogoReady]);

  useEffect(() => {
    insertFadeInKeyframes();
  }, []);

  useEffect(() => {
    if (!isPhone) {
      const handle = requestAnimationFrame(() => {
        setIsModalReady(true);
      });

      return () => cancelAnimationFrame(handle);
    }
  }, [isPhone]);

  const goBack = () => {
    window.history.back();
  };

  const [showCloseFlyout, setShowCloseFlyout] = useState(false);
  const [showBackFlyout, setShowBackFlyout] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(true);

  const goBackToFather = () => {
    setIsModalOpen(false);
    setShowCloseFlyout(false);

    // Enviar mensaje a la ventana padre para cerrar el iframe
    if (window.parent) {
      //   console.log("Enviando mensaje modalClosed al padre");
      setTimeout(window.parent.postMessage("modalClosed", "*"), 1200000); // Reemplaza con el dominio de la página padre
    }
  };

  const goBackToRoot = () => {
    navigate("/");
    setShowBackFlyout(false);
  };

  const handleCloseFlyoutShow = () => {
    setShowCloseFlyout(true);
  };
  const handleBackFlyoutShow = () => {
    setShowBackFlyout(true);
  };
  const handleCloseFlyoutClose = () => {
    setShowCloseFlyout(false);
  };
  const handleBackFlyoutClose = () => {
    setShowBackFlyout(false);
  };

  // #1
  const tourContainerGridStyling = {
    width: isPhone ? "100%" : "",
    flexGrow: isPhone ? "" : "1",
  };

  // #2
  // HeadSelect

  // #3
  // phoneHeader -> 4.75vh
  const phoneHeaderStyling = {
    height: isPhone ? (isRotated ? "4.75vw" : "4.75vh") : "4.75vh",
  };

  // #4
  const headMainWrapStyling = {
    position: isPhone ? "sticky" : "",
    height: isPhone ? (isRotated ? "10svw" : "14svh") : "14svh",
  };

  // #5
  const absoluteWrapperStyling = {
    height: isPhone ? (isRotated ? "8svw" : "12svh") : "12svh",
    maxHeight: isPhone ? (isRotated ? "8svw" : "12svh") : "12svh",
  };

  // #6
  //  mainHeadSelectStyling

  // #7
  // dividerContainer

  // #-
  const bodySelectStyling = {
    marginTop: isPhone ? (isRotated ? "14.75svw" : "18.75svh") : "14svh",
  };

  const handleLogoClick = () => {
    // Si la ruta actual comienza con "/reservation/", llama a la función
    if (location.pathname.startsWith("/reservation/")) {
      handleBackFlyoutShow();
      return;
    }

    // Si no estamos en "/", navega a "/"
    if (location.pathname !== "/") {
      navigate("/");
    }
  };

  const frostedGlassStyling = isPhone && {
    ...frostedGlassStyle,
    background: themeLightStateHover,
    borderRadius: borderRadiusLarge,
  };

  const content = (
    <div
      className="tourContainerGrid stretch-to-full-modal-width"
      style={{ ...tourContainerGridStyling, ...frostedGlassStyling }}
    >
      <HeadSelect isPhone={isPhone}>
        {isPhone && (
          <div className="phoneHeader" style={phoneHeaderStyling}>
            <LanguageSelector
              className="languageSelectorPhone"
              isPhone={isPhone}
            />

            <ClosingButton onClick={handleCloseFlyoutShow} />
          </div>
        )}
        {!isPhone && <LanguageSelector />}
        <div className="headMainWrap" style={headMainWrapStyling}>
          <div className="absoluteWrapper" style={absoluteWrapperStyling}>
            <div className="mainHeadSelectWrapper">
              {screen === "TourInformation" && (
                <div className="backForwardButtonContainerOnHeadSelect">
                  <PButtonPure
                    style={{ padding: "1rem", height: "100%" }}
                    tabindex={-1}
                    hideLabel={true}
                    icon="arrow-left"
                    className="pButtonBackForwardHeadSelect"
                    onClick={goBack}
                  />
                </div>
              )}
              <div className="textTitleContainer" style={titleTextStyling}>
                {tour?.title ? (
                  <TitleMedia className="textTitleContent">
                    {tour.title}
                  </TitleMedia>
                ) : (
                  <DefaultTitleMedia
                    className="textTitleContent"
                    style={{ marginLeft: spacingFluidXSmall }}
                  >
                    {t("defaultTitle")}
                  </DefaultTitleMedia>
                )}
              </div>
              <div className="logosContainer">
                <Logo
                  ref={logoRef}
                  className="logo"
                  onClick={handleLogoClick}
                />
                {isLogoReady && <Logo className="bgLogo" style={bgLogoStyle} />}
              </div>
            </div>
            <PDivider className="dividerOnContainer w-full" />
          </div>
        </div>
      </HeadSelect>
      <div className="bodySelect" style={bodySelectStyling}>
        {children}
      </div>
      <FooterPoweredBy isPhone={isPhone} PProvider={true} />
    </div>
  );

  return (
    <>
      {isPhone
        ? content
        : isModalReady && (
            <PModal
              open={isModalOpen}
              ref={modalRef}
              className="tourContainerModal"
              onDismiss={handleCloseFlyoutShow}
              disableBackdropClick={true}
              style={{
                "--p-modal-width": "80svw",
              }}
              aria={{ "aria-label": t("arias.systemNameAria") }}
            >
              <BorderedContainer>{content}</BorderedContainer>
            </PModal>
          )}
      {showCloseFlyout && (
        <ClosingModal
          style={{
            "--p-modal-width": "",
          }}
          accept={goBackToFather}
          onDismiss={handleCloseFlyoutClose}
        />
      )}
      {showBackFlyout && (
        <ClosingModal
          style={{
            "--p-modal-width": "",
          }}
          accept={goBackToRoot}
          onDismiss={handleBackFlyoutClose}
        />
      )}
    </>
  );
};

export default TourContainer;
