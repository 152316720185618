// TourInformation.js
import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "axiosConfig";
import { PSpinner } from "@porsche-design-system/components-react";
import InformationDescriptionSide from "Components/InformationFolder/InformationDescriptionSide/InformationDescriptionSide";
import InformationBookSide from "Components/InformationFolder/InformationBookSide/InformationBookSide";
import { slugify } from "MainApp/assets/utils/js/slugyfy";
import { ToursContext } from "MainApp/assets/utils/context/TourContext";
import "./TourInformation.css";

const TourInformation = ({ isPhone }) => {
  const { getTourBySlug, setTours } = useContext(ToursContext);
  const { title: slug } = useParams();
  const [tour, setTour] = useState(null);
  const navigate = useNavigate();
  const tourInformationStyling = {
    display: isPhone ? "flex" : "grid",
  };
  useEffect(() => {
    let mounted = true;

    const fetchTour = async () => {
      let tourData = getTourBySlug(slug);

      if (tourData) {
        setTour(tourData);
      } else {
        try {
          const response = await axiosInstance.get(
            "/api/toursAPI/get-tour-by-slug",
            {
              params: { slug },
            }
          );

          if (response.data) {
            const tourFromServer = response.data;
            // Generamos el slug para el tour recibido
            const tourSlug = slugify(tourFromServer.name);
            const tourData = {
              id: tourFromServer.tourUUID,
              title: tourFromServer.name,
              slug: tourSlug,
              price: tourFromServer.priceMin,
              duration: tourFromServer.duration,
              sprice: tourFromServer.stripe_price_id,
              // images:
              // tourFromServer.cover_image_uri.startsWith("http://") ||
              // tourFromServer.cover_image_uri.startsWith("https://")
              //     ? tourFromServer.cover_image_uri
              //     : `${process.env.REACT_APP_API_BASE_URL}${tourFromServer.cover_image_uri}`,
              // description: tourFromServer.description,
              // price: tourFromServer.stripe_product_id,
              // price: tourFromServer.position,
              // Mapear otros campos según sea necesario
            };
            // Actualizar el contexto si es necesario
            setTours((prevTours) => [...prevTours, tourData]);
            setTour(tourData);
          } else {
            navigate("/404");
          }
        } catch (error) {
          console.error("Error fetching tour:", error);
          navigate("/404");
        }
      }
    };

    if (mounted) {
      fetchTour();
    }

    return () => {
      mounted = false;
    };
  }, [slug, getTourBySlug, navigate, setTours]);

  if (!tour) {
    return (
      <div className="spinnerContainerInfo">
        <PSpinner />
      </div>
    );
  }

  return (
    <div className="tourInformation" style={tourInformationStyling}>
      <InformationDescriptionSide tour={tour} isPhone={isPhone} />
      {!isPhone && <InformationBookSide tour={tour} />}
    </div>
  );
};

export default TourInformation;
