import React from "react";
import "./InformationBookSide.css";
import CallToBook from "Components/CallToBook/CallToBook";

const InformationBookSide = ({ tour }) => {
  // Verificar si tour es null o undefined
  if (!tour) {
    return <div>Loading...</div>;
  }

  return (
    <div className="informationBookSide">
      <CallToBook tour={tour} isSimple={true} />
    </div>
  );
};

export default InformationBookSide;
