// PhoneNumberInput.js

import React, { memo, useState, useEffect } from 'react';
import { PTextFieldWrapper } from '@porsche-design-system/components-react';
import './PhoneNumberInput.css';
import { spacingFluidSmall } from '@porsche-design-system/components-js/styles';
import { useTranslation } from 'react-i18next';

const PhoneNumberInput = memo(({ phoneRef, countryCodeRef,
    textOrigin,
  textBranch,
   onPhoneChange, isPhone, onError }) => {
    const { t } = useTranslation(textOrigin);
    const tt = (field) => t(textBranch + field);
  const MAX_TOTAL_DIGITS = 15; // Maximum digits according to E.164
  const MIN_PHONE_DIGITS = 6; // Minimum digits for phone number
  const MIN_COUNTRY_CODE_DIGITS = 1; // Minimum digits for country code

  // State variables for validation
  const [countryCodeError, setCountryCodeError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  // State variables to track if fields have been touched
  const [countryCodeTouched, setCountryCodeTouched] = useState(false);
  const [phoneNumberTouched, setPhoneNumberTouched] = useState(false);

  // Function to count digits (excluding '+')
  const countDigits = (str) => {
    return (str.match(/\d/g) || []).length;
  };

  // Function to check if the field has a value
  const hasValue = (ref) => {
    return ref.current && ref.current.value && ref.current.value.trim() !== '';
  };

  // Ensure the prefix always starts with '+'
  const handleCountryCodeInput = (event) => {
    let value = event.target.value;

    // Correct the value to start with '+', allowing only numbers after '+'
    if (!value.startsWith('+')) {
      value = `+${value.replace(/\D/g, '')}`;
    } else {
      value = `+${value.slice(1).replace(/\D/g, '')}`;
    }

    // Limit the maximum length of the prefix
    if (value.length > 5) {
      value = value.slice(0, 5);
    }

    event.target.value = value;

    // Update formData.phone in the parent
    if (onPhoneChange) {
      const updatedPhoneNumber = phoneRef.current ? phoneRef.current.value : '';
      onPhoneChange(value, updatedPhoneNumber);
    }
  };

  // Function to handle phone number input (allow only numbers)
  const handlePhoneNumberInput = (event) => {
    let value = event.target.value;
    // Remove any character that's not a number
    value = value.replace(/\D/g, '');

    // Get current digits
    const countryCode = countryCodeRef.current ? countryCodeRef.current.value : '';
    const countryCodeDigits = countDigits(countryCode);
    const allowedPhoneDigits = MAX_TOTAL_DIGITS - countryCodeDigits;

    // Limit the length of the phone number
    if (value.length > allowedPhoneDigits) {
      value = value.slice(0, allowedPhoneDigits);
    }

    event.target.value = value;

    // Update formData.phone in the parent
    if (onPhoneChange) {
      const updatedCountryCode = countryCodeRef.current ? countryCodeRef.current.value : '';
      onPhoneChange(updatedCountryCode, value);
    }
  };

  // Function to validate phone number and country code
  const validatePhoneNumber = () => {

    const countryCode = countryCodeRef.current ? countryCodeRef.current.value : '';
    const phoneNumber = phoneRef.current ? phoneRef.current.value : '';
    const countryCodeDigits = countDigits(countryCode);
    const phoneNumberDigits = countDigits(phoneNumber);

    let countryCodeHasError = false;
    let phoneNumberHasError = false;

    // Validate country code (minimum 2 digits)
    if (countryCodeDigits < MIN_COUNTRY_CODE_DIGITS) {
      countryCodeHasError = true;
    }

    // Validate phone number (minimum 6 digits)
    if (phoneNumberDigits < MIN_PHONE_DIGITS) {
      phoneNumberHasError = true;
    }

    setCountryCodeError(countryCodeHasError);
    setPhoneNumberError(phoneNumberHasError);

    // Pass errors up via onError prop
    if (onError) {
      onError({
        countryCodeError: countryCodeHasError,
        phoneNumberError: phoneNumberHasError,
      });
    }
  };

  // useEffect to handle autofill or changes in inputs
  useEffect(() => {
    // Check if fields have values (e.g., auto-filled)
    const countryCodeHasValue = hasValue(countryCodeRef);
    const phoneNumberHasValue = hasValue(phoneRef);

    // If fields have values, validate them
    if (countryCodeHasValue || phoneNumberHasValue) {
      validatePhoneNumber();
    }

    // Also, if fields have values and haven't been touched, set touched to true
    if (countryCodeHasValue && !countryCodeTouched) {
      setCountryCodeTouched(true);
    }
    if (phoneNumberHasValue && !phoneNumberTouched) {
      setPhoneNumberTouched(true);
    }
  }, [
    countryCodeRef.current ? countryCodeRef.current.value : '',
    phoneRef.current ? phoneRef.current.value : '',
  ]);

  return (
    <div className="flex flex-row" style={{ gap: spacingFluidSmall }}>
      <PTextFieldWrapper
        label={`${tt(".ph.prefix")}:`}
        className="confirmationLabel"
        style={{ width: isPhone ? '25%' : '15%' }}
        state={countryCodeError && countryCodeTouched ? 'error' : undefined}
        message={countryCodeError && countryCodeTouched ? 'Prefix requires at least 1 digit.' : undefined}
      >
        <input
          type="tel"
          placeholder={`${tt(".ph.countryCode")}`}
          ref={countryCodeRef}
          className="confirmationInput"
          required
          maxLength={5}
          onInput={handleCountryCodeInput}
          onBlur={() => {
            setCountryCodeTouched(true);
            validatePhoneNumber();
          }}
        />
      </PTextFieldWrapper>

      <PTextFieldWrapper
        label={`${tt(".head")}:`}
        className="confirmationLabel"
        style={{ width: '90%' }}
        state={phoneNumberError && phoneNumberTouched ? 'error' : undefined}
        message={phoneNumberError && phoneNumberTouched ? 'Phone number requires at least 6 digits.' : undefined}
      >
        <input
          type="tel"
          placeholder={`${tt(".ph.phone")}:`}
          ref={phoneRef}
          className="confirmationInput"
          required
          pattern="\d*"
          onInput={handlePhoneNumberInput}
          onBlur={() => {
            setPhoneNumberTouched(true);
            validatePhoneNumber();
          }}
        />
      </PTextFieldWrapper>
    </div>
  );
});

export default PhoneNumberInput;
