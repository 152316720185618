import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Importar los archivos de traducción en español
import callToBookES from './locales/es/callToBook.json';
import commonES from './locales/es/common.json';
import confirmationScreenES from './locales/es/confirmationScreen.json';
import leavingES from './locales/es/leaving.json';
// import mailTextsES from './locales/es/mailTexts.json';
import stickyDrawerES from './locales/es/stickyDrawer.json';
import timeSelectionES from './locales/es/timeSelection.json';
import tourContainerES from './locales/es/tourContainer.json';
import tourInfoES from './locales/es/tourInfo.json';
import translationES from './locales/es/translation.json';

// Importar los archivos de traducción en inglés
import callToBookEN from './locales/en/callToBook.json';
import commonEN from './locales/en/common.json';
import confirmationScreenEN from './locales/en/confirmationScreen.json';
import leavingEN from './locales/en/leaving.json';
// import mailTextsEN from './locales/en/mailTexts.json';
import stickyDrawerEN from './locales/en/stickyDrawer.json';
import timeSelectionEN from './locales/en/timeSelection.json';
import tourContainerEN from './locales/en/tourContainer.json';
import tourInfoEN from './locales/en/tourInfo.json';
import translationEN from './locales/en/translation.json';

// Configuración de recursos
const resources = {
    es: {
        callToBook: callToBookES,
        common: commonES,
        confirmationScreen: confirmationScreenES,
        leaving: leavingES,
        // mailTexts: mailTextsES,
        stickyDrawer: stickyDrawerES,
        timeSelection: timeSelectionES,
        tourContainer: tourContainerES,
        tourInfo: tourInfoES,
        translation: translationES,
    },
    en: {
        callToBook: callToBookEN,
        common: commonEN,
        confirmationScreen: confirmationScreenEN,
        leaving: leavingEN,
        // mailTexts: mailTextsEN,
        stickyDrawer: stickyDrawerEN,
        timeSelection: timeSelectionEN,
        tourContainer: tourContainerEN,
        tourInfo: tourInfoEN,
        translation: translationEN,
    },
};

// Inicializar i18next con React
i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources, // Tus recursos de traducción
    fallbackLng: 'en',
    ns: Object.keys(resources.es),
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
  });

export default i18next;
