const getCachedImage = async (url) => {
  try {
    const cache = await caches.open('image-cache');
    const cachedResponse = await cache.match(url, { ignoreSearch: true });

    if (cachedResponse) {
      return url;
    } else {
      const response = await fetch(url, { mode: 'no-cors' });
      if (response && response.type !== 'error') {
        await cache.put(url, response.clone());
        return url;
      } else {
        console.error('Error al descargar la imagen:', response.statusText);
        return null;
      }
    }
  } catch (error) {
    console.error('Error en getCachedImage:', error);
    return null;
  }
};

export default getCachedImage;
