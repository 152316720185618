import React, { useState, useEffect, useRef, useContext } from "react";
import PCalendar from "p-react-calendar";
import "p-react-calendar/dist/PCalendar.css";
import "./ReservationCalendar.css"; // Asegúrate de que esta ruta es correcta
import { DateTime } from "luxon";
import {
  PButtonPure,
  PSpinner,
  PText,
} from "@porsche-design-system/components-react";
import BlurFade from "@/components/magicui/blur-fade";
import { IsRotatedContext } from "MainApp/assets/utils/context/isRotatedContext";
import { spacingFluidXSmall } from "@porsche-design-system/components-js/styles";
import axiosInstance from "axiosConfig";
import { t } from "i18next";

const ReservationCalendar = ({ onDaySelect, isPhone, tourUUID }) => {
  const [date, setDate] = useState(new Date());
  const [disabledDates, setDisabledDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const previousActiveStartDate = useRef(activeStartDate);
  const [currentView, setCurrentView] = useState("month");

  useEffect(() => {
    fetchDisabledDates(activeStartDate);
    previousActiveStartDate.current = activeStartDate;
  }, []);

  const fetchDisabledDates = async (date) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `api/toursAPI/${tourUUID}/get-unavailable-days`
      );
      setDisabledDates(response.data);
    } catch (error) {
      console.error("Error fetching disabled dates", error);
    }
    setLoading(false);
  };

  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const dateString = `${year}-${month}-${day}`;
      const isPastDate = date < new Date().setHours(0, 0, 0, 0);
      return (
        disabledDates.includes(dateString) ||
        isPastDate ||
        date.getMonth() !== activeStartDate.getMonth()
      );
    }
    return false;
  };

  const handleActiveStartDateChange = ({ activeStartDate, view }) => {
    const prevDate = previousActiveStartDate.current;
    const viewChanged = currentView !== view;

    if (viewChanged) {
      setCurrentView(view);
    }

    if (
      (prevDate.getFullYear() !== activeStartDate.getFullYear() ||
        prevDate.getMonth() !== activeStartDate.getMonth()) &&
      !viewChanged
    ) {
      setActiveStartDate(activeStartDate);
      fetchDisabledDates(activeStartDate);
      previousActiveStartDate.current = activeStartDate;
    }
  };

  const handleClickDay = (date) => {
    const dateTimeInMadrid = DateTime.fromJSDate(date, {
      zone: "Europe/Madrid",
    });
    const epochTime = dateTimeInMadrid.toSeconds();
    onDaySelect(epochTime);
  };

  const goBack = () => {
    window.history.back();
  };

  const { isRotated } = useContext(IsRotatedContext);
  const rotatedStyle = {
    height: isRotated ? "100svw" : "100svh",
  };

  return (
    <BlurFade className="h-full relative w-full">
      {!loading && (
        <PButtonPure
          className="back-button-calendar"
          icon="arrow-left"
          hideLabel={true}
          tabindex={-1}
          onClick={goBack}
          style={{ marginLeft: isPhone ? "" : spacingFluidXSmall }}
        />
      )}
      {loading ? (
        <div className="spinnerContainer">
          <PSpinner className="spinnerCalendar" />
          <PText>{t("checkingAvailability")}</PText>
        </div>
      ) : (
        <BlurFade
          className="flex w-full justify-center relative"
          style={rotatedStyle}
        >
          <PCalendar
            className={isPhone ? "react-calendar-mobile" : ""}
            onChange={setDate}
            value={date}
            tileDisabled={tileDisabled}
            onClickDay={handleClickDay}
            onActiveStartDateChange={handleActiveStartDateChange}
            activeStartDate={activeStartDate}
            onViewChange={({ activeStartDate, view }) => {
              setCurrentView(view);
              setActiveStartDate(activeStartDate);
            }}
          />
        </BlurFade>
      )}
    </BlurFade>
  );
};

export default ReservationCalendar;
