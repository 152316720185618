import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { BentoCard } from "Components/uiElements/UIComponents/bento-grid/bento-grid";
import "./TourList.css";
import {
  GridContainer,
  scrollToTop,
  SkeletonTourListPreload,
} from "MainApp/assets/visual/styles/globalStyles/globalJsxStyles";
import { ToursContext } from "MainApp/assets/utils/context/TourContext";
import { slugify } from "MainApp/assets/utils/js/slugyfy";

function TourList() {
  const { tours, setTours, setLoading } = useContext(ToursContext);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/toursAPI/get-list`)
      .then((response) => {
        if (response.data && Array.isArray(response.data.tours)) {
          const transformedTours = response.data.tours.map((tour) => {
            const tourTitle = tour.name;
            const tourSlug = slugify(tourTitle);

            return {
              id: tour.tourUUID,
              title: tourTitle,
              slug: tourSlug,
              description: tour.description,
              images:
                tour.cover_image_uri.startsWith("http://") ||
                tour.cover_image_uri.startsWith("https://")
                  ? tour.cover_image_uri
                  : `${process.env.REACT_APP_API_BASE_URL}${tour.cover_image_uri}`,
              price: tour.priceMin,
              sprice: tour.stripe_price_id,
              duration: tour.duration,
            };
          });
          setTours(transformedTours);
        } else {
          console.error(
            "API response does not contain an array of tours:",
            response.data
          );
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the tours!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, setTours]);

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  scrollToTop();
  return (
    <div className="bodySelectTourList">
      <>
        {!imageLoaded && <SkeletonTourListPreload />}
        <GridContainer>
          {Array.isArray(tours) &&
            tours.length > 0 &&
            tours.map((tour, index) => (
              <BentoCard
                key={tour.id}
                tourState={tour}
                index={index}
                {...tour}
                onImagesLoad={handleImageLoad}
                imageLoaded={imageLoaded}
              />
            ))}
        </GridContainer>
      </>
    </div>
  );
}

export default TourList;
