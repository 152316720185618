import React, { useState, useEffect } from 'react';
import './FooterPoweredBy.css';
import { PDivider, PorscheDesignSystemProvider, PText } from "@porsche-design-system/components-react";

const FooterPoweredBy = ({ isPhone: propIsPhone, PProvider, enableInternalIsPhoneCheck = false }) => {
    const [isPhone, setIsPhone] = useState(propIsPhone);
    const spacingFluidMedium = "clamp(16px, 1.25vw + 12px, 36px)";
    const spacingFluidSmall = "clamp(8px, 0.5vw + 6px, 16px)";
    const spacingFluidXSmall = "clamp(4px, 0.25vw + 3px, 8px)";
    useEffect(() => {
        if (enableInternalIsPhoneCheck) {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            let device = 'PC';

            if (/android/i.test(userAgent)) {
                device = "Android";
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                device = "iOS";
            } else if (/windows phone/i.test(userAgent)) {
                device = "Windows Phone";
            }

            setIsPhone(device !== 'PC');
        }
    }, [enableInternalIsPhoneCheck]);

    const footerSelectBodyHeadStyling = {
        gap: isPhone ? "" : spacingFluidXSmall,
        flexDirection: isPhone ? "column" : "row",
    };

    const footerSelectBodyStyling = {
        margin: isPhone
            ? `${spacingFluidMedium} ${spacingFluidSmall} ${spacingFluidSmall} ${spacingFluidSmall}`
            : `${spacingFluidSmall} ${spacingFluidSmall} ${spacingFluidSmall} ${spacingFluidSmall}`,
    };

    const subStyling = {
        base: isPhone ? "xx-small" : "x-small",
        l: "x-small"
    };
    const color = "contrast-low";
    const content = (
        <div className='footerSelect'>
            <PDivider className="w-full" />
            <div className='footerSelectBody' style={footerSelectBodyStyling}>
                <div className='footerSelectBodyHead' style={footerSelectBodyHeadStyling}>
                    <PText align='end' size="x-small" color={color}>Powered by</PText>
                    <PText align='end' size="medium" color={color}>SK Gate</PText>
                    {/* <PText align='end' size="medium" color={color}>Future Solutions</PText> */}
                </div>
                <PText align='end' size={subStyling} color='contrast-low' >The Gate To Your Dreams</PText>
                {/* <PText align='end' size={subStyling} color='contrast-low' >The future is in our hands</PText> */}
            </div>
        </div>
    );
    const poweredStyling = {
        backgroundColor: "whitesmoke"
    }
    return (
        <div
            className='footerPoweredBy'
        // style={poweredStyling}
        >
            {!PProvider ? (
                <PorscheDesignSystemProvider>
                    {content}
                </PorscheDesignSystemProvider>
            )
                :
                (content)
            }
        </div>
    );
};

export default FooterPoweredBy;
