// AppLandScapeEnforcer.jsx
import React, { useEffect, useState, useRef, useContext } from 'react';
import './AppLandScapeEnforcer.css';
import { IsRotatedContext } from 'MainApp/assets/utils/context/isRotatedContext';

const AppLandScapeEnforcer = ({ children }) => {
  const flexRef = useRef(null);
  const [isLandscape, setIsLandscape] = useState(false);
  const { setIsRotated } = useContext(IsRotatedContext);

  useEffect(() => {
    // Función para actualizar la orientación y estilos
    const updateOrientation = () => {
      const isLandscapeMode = window.matchMedia("(orientation: landscape)").matches;
      setIsLandscape(isLandscapeMode);
      setIsRotated(isLandscapeMode);
    };

    // Llamada inicial para establecer el estado correcto
    updateOrientation();

    // Escuchar cambios en la orientación
    const mediaQuery = window.matchMedia("(orientation: landscape)");
    mediaQuery.addEventListener('change', updateOrientation);

    // Limpiar el listener al desmontar el componente
    return () => {
      mediaQuery.removeEventListener('change', updateOrientation);
    };
  }, []);

  return (
    <div className='flexSpace' ref={flexRef}>
      {children}
    </div>
  );
};

export default AppLandScapeEnforcer;
