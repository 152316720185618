// TuktukModelsSection.js
import React, { useEffect, useState } from "react";
import {
  PSelect,
  PSelectOption,
  PButton,
  PButtonPure,
  PSpinner,
  PText,
  PInlineNotification,
  PFieldsetWrapper,
} from "@porsche-design-system/components-react";
import "./TuktukModelsSection.css";
import {
  spacingFluidLarge,
  spacingFluidSmall,
  spacingFluidXSmall,
} from "@porsche-design-system/components-js/styles";
import { useTranslation } from "react-i18next";

const TuktukModelsSection = ({
  loadingModels,
  modelsFetched,
  tuktukModels,
  formData,
  persons,
  adults,
  setFormData,
  addTuktukModel,
  removeTuktukModel,
  textOrigin,
  textBranch,
  textsExtra,
  textsHeader,
  setErrors,
}) => {
  // Actualizar el total de unidades y capacidad cuando se cambia el número de modelos o unidades
  // console.log("persons on TukTuk");
  // console.log(persons);

  const { t } = useTranslation(textOrigin);
  const tt = (field) => t(textBranch + field);

  const [unitErrors, setUnitErrors] = useState(false);

  // Filtrar solo los modelos que tienen un valor seleccionado
  const selectedModels = formData.tuktukModels.filter((model) => model.value);

  useEffect(() => {
    const totalUnits = selectedModels.reduce(
      (acc, model) => acc + model.units,
      0
    );

    const totalCapacity = selectedModels.reduce((acc, model) => {
      const modelData = tuktukModels.find((m) => m.value === model.value);
      const maxPeople = modelData ? modelData.maxPeople : 0;
      return acc + model.units * maxPeople;
    }, 0);

    setFormData((prevFormData) => ({
      ...prevFormData,
      totalUnits,
      totalCapacity,
    }));
  }, [formData.tuktukModels, tuktukModels, setFormData, selectedModels]);

  const totalUnits = selectedModels.reduce(
    (acc, model) => acc + model.units,
    0
  );
  // console.log("totalUnits");
  // console.log(totalUnits);
  const totalModels = selectedModels.length;

  const totalCapacity = selectedModels.reduce((acc, model) => {
    const modelData = tuktukModels.find((m) => m.value === model.value);
    const maxPeople = modelData ? modelData.maxPeople : 0;
    return acc + model.units * maxPeople;
  }, 0);

  const maxUnitsReached = totalUnits >= adults;
  const maxModelsReached =
    totalModels >= tuktukModels.length || totalUnits >= adults;
  const insufficientCapacity = totalCapacity < persons;

  // Determinar si hay algún error activo y actualizar setErrors
  useEffect(() => {
    if (selectedModels.length > 0) {
      if (insufficientCapacity || totalUnits > adults) {
        setErrors(true);
        setUnitErrors(true);
      } else {
        setErrors(false);
        setUnitErrors(false);
      }
    } else {
      // Si no hay vehículos seleccionados, no marcar como error
      setErrors(false);
      setUnitErrors(false);
    }
  }, [
    insufficientCapacity,
    maxUnitsReached,
    setErrors,
    totalUnits,
    adults,
    selectedModels.length,
  ]);

  if (loadingModels) {
    return (
      <div className="flex items-center space-x-2">
        <PSpinner />
        <PText>{tt(".loadingModels")}</PText>
      </div>
    );
  }

  if (modelsFetched && tuktukModels.length === 0) {
    setErrors(true);
    setUnitErrors(true);
    return (
      <PInlineNotification
        dismissButton={false}
        state="error"
        message={tt(".error.NoModelAvailable.main")}
        description={tt(".error.NoModelAvailable.explanation")}
      />
    );
  }

  const validTuktukModels = tuktukModels.filter((option) => option.value);

  return (
    <PFieldsetWrapper label={`${textsHeader} (${textsExtra}: ${persons})`}>
      <div
        style={{
          gap: spacingFluidSmall,
          display: "flex",
          flexDirection: "column",
          marginBottom: spacingFluidXSmall,
        }}
      >
        {formData.tuktukModels &&
          formData.tuktukModels.map((model, index) => {
            const modelData = tuktukModels.find((m) => m.value === model.value);
            const modelMaxAvailableUnits = modelData
              ? modelData.availableUnits
              : 0;

            let unitsAvailableForThisModel;

            if (totalUnits >= adults) {
              unitsAvailableForThisModel = model.units;
            } else {
              unitsAvailableForThisModel = Math.min(
                modelMaxAvailableUnits,
                adults - totalUnits + model.units
              );
            }

            unitsAvailableForThisModel = Math.max(
              unitsAvailableForThisModel,
              1
            );

            return (
              <div
                key={index}
                className="flex items-end"
                style={{ gap: spacingFluidSmall }}
              >
                <PSelect
                  name={`car-model-${model.id}`}
                  label={`${tt(".modelField")} ${index + 1}`}
                  className="w-full"
                  value={model.value || ""}
                  onUpdate={(e) => {
                    const newModels = [...formData.tuktukModels];
                    const selectedValue = e.detail.value;
                    const modelData = tuktukModels.find(
                      (m) => m.value === selectedValue
                    );

                    if (modelData) {
                      newModels[index] = {
                        ...newModels[index],
                        value: selectedValue,
                        units: 1, // Reiniciar las unidades si se selecciona un nuevo modelo
                        maxPeople: modelData.maxPeople, // Añadir maxPeople
                        label: modelData.label, // Añadir el nombre del modelo
                        availableUnits: modelData.availableUnits,
                      };
                    } else {
                      newModels[index] = {
                        ...newModels[index],
                        value: selectedValue,
                        units: 1,
                      };
                    }

                    setFormData({ ...formData, tuktukModels: newModels });
                  }}
                  required
                  state={
                    unitErrors && selectedModels.length > 0
                      ? "error"
                      : undefined
                  } // Actualizado
                  style={{ zIndex: 10 }}
                >
                  {validTuktukModels
                    .filter(
                      (option) =>
                        !formData.tuktukModels.some(
                          (selectedModel, idx) =>
                            selectedModel.value === option.value &&
                            idx !== index
                        )
                    )
                    .map((option) => (
                      <PSelectOption
                        key={option.value}
                        value={option.value}
                        disabled={option.availableUnits <= 0}
                        style={{ zIndex: 10 }}
                      >
                        {`${option.label} (${tt(
                          ".modelDescription.passengers"
                        )}: ${
                          option.maxPeople > 1
                            ? `1 - ${option.maxPeople}`
                            : `${option.maxPeople}`
                        }) - ${tt(".modelDescription.availableUnits")}: ${
                          option.availableUnits < 0 ? 0 : option.availableUnits
                        }`}
                      </PSelectOption>
                    ))}
                </PSelect>

                <PSelect
                  value={model.units.toString()}
                  onUpdate={(e) => {
                    const newModels = [...formData.tuktukModels];
                    const selectedUnits = parseInt(e.detail.value, 10);
                    const maxAvailableUnits =
                      totalUnits >= adults
                        ? model.units
                        : Math.min(
                            modelMaxAvailableUnits,
                            adults - totalUnits + model.units
                          );

                    newModels[index].units = Math.min(
                      selectedUnits,
                      maxAvailableUnits
                    );
                    setFormData({ ...formData, tuktukModels: newModels });
                  }}
                  name={`car-model-${model.id}`}
                  label={tt(".amountField")}
                  className="w-[100px]"
                  disabled={!model.value}
                  state={
                    unitErrors && selectedModels.length > 0
                      ? "error"
                      : undefined
                  } // Actualizado
                >
                  {Array.from({ length: modelMaxAvailableUnits }, (_, i) => {
                    const optionUnits = i + 1;
                    const newTotalUnits =
                      totalUnits - model.units + optionUnits;
                    const disabled = newTotalUnits > adults;
                    return (
                      <PSelectOption
                        key={i}
                        value={optionUnits.toString()}
                        disabled={disabled}
                      >
                        {optionUnits}
                      </PSelectOption>
                    );
                  })}
                </PSelect>

                {formData.tuktukModels.length > 1 && (
                  <PButton
                    icon="delete"
                    hideLabel={true}
                    onClick={() => removeTuktukModel(index)}
                  />
                )}
              </div>
            );
          })}
      </div>
      <div
        style={{
          gap: spacingFluidXSmall,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!maxModelsReached && adults > 1 && (
          <div style={{ display: "block !important" }}>
            <PButtonPure icon="add" onClick={addTuktukModel}>
              {tt(".add")}
            </PButtonPure>
          </div>
        )}

        {selectedModels.length > 0 && totalUnits > adults && (
          <PInlineNotification
            dismissButton={false}
            state="error"
            heading={tt(".error.TooMany.main")}
            description={tt(".error.TooMany.explanation")}
          />
        )}

        {selectedModels.length > 0 && insufficientCapacity && (
          <PInlineNotification
            dismissButton={false}
            state="error"
            heading={`${tt(".error.NoPlaces")} ${persons - totalCapacity}`}
          />
        )}
      </div>
    </PFieldsetWrapper>
  );
};

export default TuktukModelsSection;
