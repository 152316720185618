import {
  PModal,
  PButton,
  PText,
  PButtonGroup,
  PHeading,
} from "@porsche-design-system/components-react";
import { useTranslation } from "react-i18next";

export const ClosingModal = ({
  accept,
  onDismiss,
  questionText,
  descriptionText,
}) => {
  const { t } = useTranslation("leaving");

  return (
    <>
      <PModal
        open={true}
        onDismiss={onDismiss}
        aria={{ "aria-label": "Some Heading" }}
      >
        <PHeading slot="header" size="large" tag="h2">
          {questionText || t("modal.head")}
        </PHeading>
        <PText>{descriptionText || t("modal.line")}</PText>
        <PButtonGroup slot="footer">
          <PButton type="button" onClick={accept}>
            {t("modal.button.accept")}
          </PButton>
          <PButton type="button" variant="secondary" onClick={onDismiss}>
            {t("modal.button.decline")}
          </PButton>
        </PButtonGroup>
      </PModal>
      ) :
    </>
  );
};
