// SuccessScreen.js
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './SuccessScreen.module.css';
import axiosInstance from 'axiosConfig';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import { LanguageContext } from 'MainApp/assets/utils/context/LanguageContext';
import { getCountryNames } from 'Components/ReservationCalendar/PersonalInfoFields/PersonalInfoFields';

const SuccessScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { language: languageCode } = useContext(LanguageContext);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [countryNames, setCountryNames] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');

    const getSessionDetails = async () => {
      try {
        const response = await axiosInstance.get(`/api/stripe/session/${sessionId}`);
        if (response.data.success) {
          setBookingDetails(response.data.metadata);
        } else {
          setErrorMessage(response.data.message || 'La sesión no es válida. Por favor, contacta al soporte.');
        }
      } catch (error) {
        setErrorMessage('Ocurrió un error al obtener los detalles de la sesión. Por favor, inténtalo de nuevo más tarde.');
      } finally {
        setIsLoading(false);
      }
    };

    if (sessionId) {
      getSessionDetails();
    } else {
      setErrorMessage('No se proporcionó un ID de sesión válido en la URL.');
      setIsLoading(false);
    }
  }, [location]);

  useEffect(() => {
    const fetchCountryNames = async () => {
      const countryList = await getCountryNames(languageCode);
      const countryMap = countryList.reduce((acc, country) => {
        acc[country.value] = country.label;
        return acc;
      }, {});
      setCountryNames(countryMap);
    };

    fetchCountryNames();
  }, [languageCode]);

  const safeValue = (value, defaultValue = 'N/A') => {
    return value !== null && value !== undefined && value !== '' ? value : defaultValue;
  };

  const parseTuktukModels = (models) => {
    try {
      // Parsear la cadena JSON a un arreglo de objetos
      const parsedModels = JSON.parse(models);

      // Verificar que parsedModels sea un arreglo
      if (Array.isArray(parsedModels)) {
        // Mapear cada modelo al formato { id, label }
        return parsedModels.map((model) => ({
          id: model.value,
          label: `${model.label} x${model.units}`,
        }));
      } else {
        return [{ id: 'n/a', label: 'N/A' }];
      }
    } catch (error) {
      console.error('Error al parsear tuktukModels:', error);
      return [{ id: 'n/a', label: 'N/A' }];
    }
  };

  return (
    <div className={styles.successScreen}>
      <div className={styles.successContainer}>
        {isLoading ? (
          <p>Cargando detalles de la reserva...</p>
        ) : errorMessage ? (
          <div className={styles.errorContainer}>
            <FaExclamationTriangle className={styles.errorIcon} />
            <h1>Error</h1>
            <p>{errorMessage}</p>
          </div>
        ) : (
          <>
            <FaCheckCircle className={styles.successIcon} />
            <h1>¡Pago Completado!</h1>
            <p>Tu transacción se ha completado con éxito. A continuación encontrarás los detalles de tu reserva.</p>
            <div className={styles.bookingDetails}>
              <h2>Detalles de la Reserva</h2>
              <div className={styles.detailsGrid}>
                <div className={styles.detailSection}>
                  <h3>Información Personal</h3>
                  <p>
                    <strong>Nombre:</strong> {safeValue(bookingDetails?.clientName)}
                  </p>
                  <p>
                    <strong>Email:</strong> {safeValue(bookingDetails?.clientEmail)}
                  </p>
                  <p>
                    <strong>Teléfono:</strong> {safeValue(bookingDetails?.clientPhone)}
                  </p>
                  <p>
                    <strong>País:</strong> {countryNames[bookingDetails?.clientCountry] || safeValue(bookingDetails?.clientCountry)}
                  </p>
                </div>
                <div className={styles.detailSection}>
                  <h3>Detalles del Viaje</h3>
                  <p>
                    <strong>Adultos:</strong> {safeValue(bookingDetails?.adults)}
                  </p>
                  <p>
                    {bookingDetails?.minorsTotal ? (
                      <>
                        <strong>Menores:</strong> {safeValue(bookingDetails?.minorsTotal)}
                      </>
                    ) : (
                      <strong>Sin menores</strong>
                    )}
                  </p>
                </div>
                <div className={styles.detailSection}>
                  <h3>Información Adicional</h3>
                  <p>
                    <strong>Modelos TukTuk:</strong>
                  </p>
                  <ul className={styles.modelList}>
                    {parseTuktukModels(bookingDetails?.tuktukModels).map((model) => (
                      <li key={model.id}>{model.label}</li>
                    ))}
                  </ul>

                </div>
                <div className={styles.detailSection}>
                <h3>Identificadores únicos:</h3>
                  <p>
                    <strong>Identificador único de la reserva:</strong><br /> {bookingDetails?.bookingUUID || 'N/A'}
                  </p>
                </div>
              </div>
            </div>
            <button onClick={() => navigate('/')} className={styles.backButton}>
              Volver a la página principal
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default SuccessScreen;
