import React from "react";
import {
  PTextFieldWrapper,
  PButton,
  PFieldsetWrapper,
} from "@porsche-design-system/components-react";
import "./RenderHumanDataFields.css";
import MinorsSection from "../MinorSection/MinorSection";
import { spacingFluidXSmall } from "@porsche-design-system/components-js/styles";
import { useTranslation } from "react-i18next";

// const RenderHumanDataFields = ({ adults, setAdults, minors, setMinors, texts, handleMinorAgeChange, handleRemoveMinor, handleAddMinor }) => {
const RenderHumanDataFields = ({
  adults,
  setAdults,
  textOrigin,
  textBranch,
  minors,
  setMinors,
  handleMinorAgeChange,
  handleRemoveMinor,
  handleAddMinor,
  headerText,
}) => {
  const incrementPersons = () => {
    setAdults((prevAdults) => prevAdults + 1);
  };

  const decrementPersons = () => {
    setAdults((prevAdults) => (prevAdults > 1 ? prevAdults - 1 : 1)); // Asegurarse de no bajar de 1
  };
  const { t } = useTranslation(textOrigin);
  const tt = (field) => t(textBranch + field);

  return (
    <PFieldsetWrapper label={headerText}>
      <div
        className="adultWrapperHumanData"
        style={{
          gap: spacingFluidXSmall,
          marginBottom: spacingFluidXSmall,
        }}
      >
        <PTextFieldWrapper
          label={`${tt(".adults.head")}:`}
          className="adultTextFieldHumanData"
        >
          <input
            type="number"
            value={adults}
            onChange={(e) => setAdults(parseInt(e.target.value, 10))}
            min="1"
          />
        </PTextFieldWrapper>
        <PButton
          icon="subtract"
          onClick={decrementPersons}
          hideLabel={true}
          disabled={adults <= 1}
          aria-label={tt(".adults.aria.decrease")}
          className="adultIncreaseButtonHumanData"
        />
        <PButton
          icon="add"
          onClick={incrementPersons}
          aria-label={tt(".adults.aria.increase")}
          hideLabel={true}
          className="adultDecreaseButtonHumanData"
        />
      </div>

      <MinorsSection
        minors={minors}
        setMinors={setMinors}
        textOrigin={textOrigin}
        textBranch={textBranch + ".minors"}
        handleMinorAgeChange={handleMinorAgeChange}
        handleRemoveMinor={handleRemoveMinor}
        handleAddMinor={handleAddMinor}
      />
    </PFieldsetWrapper>
    // </div>
  );
};

export default RenderHumanDataFields;
