// PersonalInfoFields.js

import React, { useContext, useEffect, useState } from "react";
import {
  PTextFieldWrapper,
  PSelectWrapper,
  PFieldsetWrapper,
} from "@porsche-design-system/components-react";
import "./PersonalInfoFields.css";
import countries from "i18n-iso-countries";
import { LanguageContext } from "MainApp/assets/utils/context/LanguageContext";
import { spacingFluidSmall } from "@porsche-design-system/components-js/styles";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput.jsx";
import { useTranslation } from "react-i18next";

const loadAndRegisterLanguage = async (languageCode) => {
  try {
    const localeData = await import(
      `i18n-iso-countries/langs/${languageCode}.json`
    );
    countries.registerLocale(localeData);
  } catch (error) {
    console.warn(
      `No se encontró el archivo para el idioma '${languageCode}'. Usando 'en' por defecto.`
    );
    const localeData = await import("i18n-iso-countries/langs/en.json");
    countries.registerLocale(localeData);
  }
};

// Function to get country names in a specific language
export const getCountryNames = async (languageCode) => {
  await loadAndRegisterLanguage(languageCode);

  return Object.entries(
    countries.getNames(languageCode, { select: "official" })
  ).map(([code, name]) => ({
    value: code,
    label: name,
  }));
};

const PersonalInfoFields = ({
  formData,
  setFormData,
  textBranch,
  textOrigin,
  phoneRef,
  onPhoneChange,
  countryCodeRef,
  phoneTexts,
  textHeader,
  isPhone,
  onError,
}) => {
  const { language: languageCode } = useContext(LanguageContext);
  const [countryList, setCountryList] = useState([]);

  // State variables for validation
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [countryError, setCountryError] = useState(false);

  // State variables to track if fields have been touched
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [countryTouched, setCountryTouched] = useState(false);

  useEffect(() => {
    const fetchCountryNames = async () => {
      const countries = await getCountryNames(languageCode);
      setCountryList(countries);
    };

    fetchCountryNames();
  }, [languageCode]);

  // useEffect to handle autofill or changes in inputs
  useEffect(() => {
    // Validate first name
    if (formData?.firstName?.trim()) {
      setFirstNameError(false);
    } else if (firstNameTouched) {
      setFirstNameError(true);
    }

    // Validate last name
    if (formData?.lastName?.trim()) {
      setLastNameError(false);
    } else if (lastNameTouched) {
      setLastNameError(true);
    }

    // Validate email
    if (formData?.email?.trim()) {
      setEmailError(false);
    } else if (emailTouched) {
      setEmailError(true);
    }

    // Validate country
    if (formData?.country?.trim()) {
      setCountryError(false);
    } else if (countryTouched) {
      setCountryError(true);
    }
  }, [
    formData.firstName,
    formData.lastName,
    formData.email,
    formData.country,
    firstNameTouched,
    lastNameTouched,
    emailTouched,
    countryTouched,
  ]);

  // Pass errors up via onError prop
  useEffect(() => {
    if (onError) {
      onError({
        firstNameError,
        lastNameError,
        emailError,
        countryError,
      });
    }
  }, [firstNameError, lastNameError, emailError, countryError]);

  const { t } = useTranslation(textOrigin);
  const tt = (field) => t(textBranch + field);

  const contentFirst = (
    <>
      <PTextFieldWrapper
        className="w-full"
        label={tt(".name")}
        state={firstNameError && firstNameTouched ? "error" : undefined}
        message={
          firstNameError && firstNameTouched
            ? "This field is required."
            : undefined
        }
      >
        <input
          type="text"
          value={formData.firstName || ""}
          onInput={(e) =>
            setFormData({ ...formData, firstName: e.target.value })
          }
          required
          onBlur={(e) => {
            setFirstNameTouched(true);
            if (!e.target.value.trim()) {
              setFirstNameError(true);
            } else {
              setFirstNameError(false);
            }
          }}
        />
      </PTextFieldWrapper>

      <PTextFieldWrapper
        className="w-full"
        label={tt(".surname")}
        state={lastNameError && lastNameTouched ? "error" : undefined}
        message={
          lastNameError && lastNameTouched
            ? "This field is required."
            : undefined
        }
      >
        <input
          type="text"
          value={formData.lastName || ""}
          onInput={(e) =>
            setFormData({ ...formData, lastName: e.target.value })
          }
          required
          onBlur={(e) => {
            setLastNameTouched(true);
            if (!e.target.value.trim()) {
              setLastNameError(true);
            } else {
              setLastNameError(false);
            }
          }}
        />
      </PTextFieldWrapper>
    </>
  );

  return (
    <PFieldsetWrapper label={textHeader}>
      <div className="flex flex-col" style={{ gap: spacingFluidSmall }}>
        {!isPhone ? (
          <div
            className="flex flex-row w-full"
            style={{ gap: spacingFluidSmall }}
          >
            {contentFirst}
          </div>
        ) : (
          contentFirst
        )}
        <PTextFieldWrapper
          label={tt(".mail")}
          state={emailError && emailTouched ? "error" : undefined}
          message={
            emailError && emailTouched ? "This field is required." : undefined
          }
        >
          <input
            type="email"
            value={formData.email || ""}
            onInput={(e) => setFormData({ ...formData, email: e.target.value })}
            required
            onBlur={(e) => {
              setEmailTouched(true);
              if (!e.target.value.trim()) {
                setEmailError(true);
              } else {
                setEmailError(false);
              }
            }}
          />
        </PTextFieldWrapper>

        <PSelectWrapper
          filter={true}
          label={`${tt(".country.head")}`}
          required
          state={countryError && countryTouched ? "error" : undefined}
          message={
            countryError && countryTouched
              ? "This field is required."
              : undefined
          }
        >
          <select
            value={formData.country || ""}
            onChange={(e) =>
              setFormData({ ...formData, country: e.target.value })
            }
            required
            onBlur={(e) => {
              setCountryTouched(true);
              if (!e.target.value.trim()) {
                setCountryError(true);
              } else {
                setCountryError(false);
              }
            }}
          >
            {/* Empty option at the beginning */}
            <option value="" disabled>
              {tt(".country.ph") || "Select a country"}
            </option>
            {countryList.map((country) => (
              <option key={country.value} value={country.value}>
                {country.label}
              </option>
            ))}
          </select>
        </PSelectWrapper>

        <PhoneNumberInput
          phoneRef={phoneRef}
          countryCodeRef={countryCodeRef}
          onPhoneChange={onPhoneChange}
          textOrigin={textOrigin}
          textBranch={phoneTexts}
          isPhone={isPhone}
          onError={(phoneErrors) => {
            // Combine errors and pass up via onError prop
            if (onError) {
              onError({
                firstNameError,
                lastNameError,
                emailError,
                countryError,
                ...phoneErrors,
              });
            }
          }}
        />
      </div>
    </PFieldsetWrapper>
  );
};

export default PersonalInfoFields;
