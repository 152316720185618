// utils/slugify.js

export const slugify = (text) => {
    return text
        .toString()
        .toLowerCase()
        .trim()
        .replace(/[\s\W-]+/g, '-') // Reemplaza espacios y caracteres no alfanuméricos por guiones
        .replace(/^-+|-+$/g, ''); // Elimina guiones al inicio o al final
};
