import { openDB } from 'idb';

const DB_NAME = 'images-db';
const DB_VERSION = 1;
const STORE_NAME = 'images-store';

export const openDatabase = async () => {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });
};

export const storeImage = async (id, url) => {
  try {
    const db = await openDatabase();
    const response = await fetch(url);
    const blob = await response.blob();
    await db.put(STORE_NAME, blob, id);
  } catch (error) {
    console.error('Error storing image in IndexedDB:', error);
  }
};

export const getImage = async (id) => {
  try {
    const db = await openDatabase();
    const blob = await db.get(STORE_NAME, id);

    if (blob) {
      return URL.createObjectURL(blob);
    }
    return null;
  } catch (error) {
    if (error.name === 'NotFoundError') {
      console.warn('Object store not found. Did you initialize the database correctly?');
    } else {
      console.error('Error retrieving image from IndexedDB:', error);
    }
    return null;
  }
};
