import React, { useState } from "react";
import "./TourSelection.css";
import TourList from "../../../Components/TourList";

const TourSelection = () => {

  const [isExiting] = useState(false);

  return (
    <div className={`tourSelection ${isExiting ? "fade-out" : ""}`}>
      <TourList />
    </div>
  );
};

export default TourSelection;
