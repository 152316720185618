import React, { createContext, useState, useEffect } from "react";

export const ToursContext = createContext();

// En ToursProvider.js
export const ToursProvider = ({ children }) => {
  const [tours, setTours] = useState(() => {
    const storedTours = localStorage.getItem("tours");
    const storedTimestamp = localStorage.getItem("toursTimestamp");
    const currentTime = new Date().getTime();
    const oneHour = 60 * 60 * 1000;

    if (
      storedTours &&
      storedTimestamp &&
      currentTime - storedTimestamp < oneHour
    ) {
      return JSON.parse(storedTours);
    }
    localStorage.removeItem("tours");
    localStorage.removeItem("toursTimestamp");
    return [];
  });

  const [loading, setLoading] = useState(!localStorage.getItem("tours"));

  useEffect(() => {
    if (tours.length > 0) {
      localStorage.setItem("tours", JSON.stringify(tours));
      localStorage.setItem("toursTimestamp", new Date().getTime());
    }
  }, [tours]);

  const getTourById = (id) => {
    return tours.find((tour) => tour.id === id);
  };
  const [tourImages, setTourImages] = useState(() => {
    const storedImages = localStorage.getItem("tourImages");
    return storedImages ? JSON.parse(storedImages) : {};
  });

  const getImagesByTourId = (id) => {
    return tourImages[id];
  };
  const setImagesByTourId = (id, images) => {
    setTourImages((prevImages) => {
      const updatedImages = { ...prevImages, [id]: images };
      localStorage.setItem("tourImages", JSON.stringify(updatedImages));
      return updatedImages;
    });
  };
  const getTourBySlug = (slug) => {
    return tours.find((tour) => tour.slug === slug);
  };
  return (
    <ToursContext.Provider
      value={{
        tours,
        setTours,
        loading,
        setLoading,
        getTourBySlug,
        getTourById,
        getImagesByTourId,
        setImagesByTourId,
      }}
    >
      {children}
    </ToursContext.Provider>
  );
};
