import React from "react";
import "./TourDaySelection.css";
import ReservationCalendar from "Components/ReservationCalendar/ReservationCalendar";
import WrappedReservationManager from "Components/ReservationCalendar/ReservationManager";

const TourDaySelection = () => {

    return (
        <div className="tourDaySelection">
            {/* <ReservationCalendar/> */}
            <WrappedReservationManager/>
        </div>
    );
}

export default TourDaySelection;
