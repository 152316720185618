import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n'; // Asegúrate de que este archivo existe y está correctamente configurado
import AppLandScapeEnforcer from 'Components/AppLandScapeEnforcer/AppLandScapeEnforcer';
import { IsRotatedProvider } from 'MainApp/assets/utils/context/isRotatedContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<div>Loading...</div>}>
    <React.StrictMode>
      <IsRotatedProvider>
        <AppLandScapeEnforcer>
          <App />
        </AppLandScapeEnforcer>
      </IsRotatedProvider>
    </React.StrictMode>
  </Suspense>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
