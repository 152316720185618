import React, { useState, useEffect, useContext, createContext } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import "./ReservationManager.css";
import ReservationCalendar from "./ReservationCalendar";
import TimeSelection from "./TimeSelection";
import ConfirmationScreen from "./ConfirmationScreen";
import { motion, AnimatePresence } from "framer-motion";
import { DateTime } from "luxon";
import useIsPhone from "MainApp/assets/utils/hooks/useIsPhone";
import { scrollToTop } from "MainApp/assets/visual/styles/globalStyles/globalJsxStyles";
import axiosInstance from "axiosConfig";
import { t } from "i18next";
import { slugify } from "MainApp/assets/utils/js/slugyfy";

const AppointmentContext = createContext();

const AppointmentProvider = ({ children }) => {
  const [appointmentDate, setAppointmentDate] = useState(null);

  return (
    <AppointmentContext.Provider
      value={{ appointmentDate, setAppointmentDate }}
    >
      {children}
    </AppointmentContext.Provider>
  );
};

const ReservationManager = () => {
  const isPhone = useIsPhone();
  const location = useLocation();
  const navigate = useNavigate();
  const { title: slug } = useParams();

  // Intentamos obtener el tour del estado de la navegación
  const tourFromState = location.state?.tour;
  const [tour, setTour] = useState(tourFromState || null);

  const [step, setStep] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const [formData, setFormData] = useState({
    bookingStart: 0,
    bookingEnd: 0,
    tour: "",
    client: null,
    tourUUID: null,
    customPickUp: "",
    priceUnit: 2,
    sprice: null,
    persons: 0,
    phone: 0,
    adults: 0,
    minorsTotal: 0,
    minors: false,
    minors2to5: 0,
    minors6to10: 0,
    minors11to17: 0,
    tuktukModels: [],
    totalUnits: 0,
    country: "",
    name: "",
    email: "",
    hasMinors: false,
  });

  const { setAppointmentDate } = useContext(AppointmentContext);

  // Actualizamos formData cuando se carga el tour
  useEffect(() => {
    if (tour) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        tour: tour.title || "",
        tourUUID: tour.id || null,
        priceUnit: tour.price || 2,
        sprice: tour.sprice || null,
      }));
    }
  }, [tour]);

  // Efecto para obtener el tour si no se pasó por props o está vacío
  useEffect(() => {
    let isMounted = true;

    const getTourFromCache = (slug) => {
      const cachedTours = JSON.parse(localStorage.getItem("toursCache")) || {};
      return cachedTours[slug] || null;
    };

    const setTourInCache = (slug, tourData) => {
      const cachedTours = JSON.parse(localStorage.getItem("toursCache")) || {};
      cachedTours[slug] = tourData;
      localStorage.setItem("toursCache", JSON.stringify(cachedTours));
    };

    const getTourBySlug = async (slug) => {
      try {
        const response = await axiosInstance.get(
          "/api/toursAPI/get-tour-by-slug",
          {
            params: { slug },
          }
        );

        if (response.data) {
          const tourFromServer = response.data;

          // Generamos el slug para el tour recibido
          const tourSlug = slugify(tourFromServer.name);

          const tourData = {
            id: tourFromServer.tourUUID,
            title: tourFromServer.name,
            slug: tourSlug,
            price: tourFromServer.priceMin,
            duration: tourFromServer.duration,
            sprice: tourFromServer.stripe_price_id,
            // Mapear otros campos según sea necesario
          };

          // Actualizar el contexto si es necesario
          setTour(tourData);
        } else {
          navigate("/404");
        }
      } catch (error) {
        console.error("Error fetching tour:", error);
        navigate("/404");
      }
    };

    const fetchTour = async () => {
      if (!tour) {
        try {
          // Intentamos obtener el tour del caché
          const cachedTour = getTourFromCache(slug);
          if (cachedTour) {
            if (isMounted) setTour(cachedTour);
          } else {
            // Si no está en caché, lo obtenemos del servidor
            const fetchedTour = await getTourBySlug(slug);
            if (fetchedTour) {
              // Guardamos en caché y actualizamos el estado
              setTourInCache(slug, fetchedTour);
              if (isMounted) setTour(fetchedTour);
            } else {
              // Si no encontramos el tour, redirigimos a 404
              if (isMounted) navigate("/404");
            }
          }
        } catch (error) {
          console.error("Error fetching tour:", error);
          if (isMounted) navigate("/404");
        }
      }
    };

    fetchTour();

    return () => {
      isMounted = false;
    };
  }, [slug, tour, navigate]);

  // Otros efectos y manejadores
  useEffect(() => {
    if (step === 3 && selectedDate && selectedTime) {
      const appointmentDateTime = DateTime.fromMillis(selectedDate * 1000, {
        zone: "Europe/Madrid",
      }).set({
        hour: parseInt(selectedTime.split(":")[0], 10),
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      setAppointmentDate(appointmentDateTime.toJSDate());
    }
  }, [step, selectedDate, selectedTime, setAppointmentDate]);

  useEffect(() => {
    scrollToTop();
  }, [step]);

  const handleDateSelect = (epochTime) => {
    setSelectedDate(epochTime);
    setStep(2);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    setStep(3);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  // Configuración de animaciones
  const pageVariants = {
    generalOut: {
      opacity: 0,
      transition: {
        duration: 0.6,
      },
    },
  };

  const pageTransition = {
    type: "spring",
    stiffness: 300,
    damping: 30,
  };

  // Muestra un indicador de carga mientras se obtiene el tour
  if (!tour) {
    return <div>{t("reservationCheck")}</div>;
  }

  return (
    <div className="reservationManager">
      <header className="reservationManager-header">
        <AnimatePresence mode="wait">
          {step === 1 && (
            <motion.div
              key="step1"
              variants={pageVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={pageTransition}
              className="preScreen"
            >
              <ReservationCalendar
                onDaySelect={handleDateSelect}
                isPhone={isPhone}
                tourUUID={tour.id}
              />
            </motion.div>
          )}
          {step === 2 && (
            <motion.div
              key="step2"
              variants={pageVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={pageTransition}
              className="preScreen"
            >
              <TimeSelection
                date={selectedDate}
                onTimeSelect={handleTimeSelect}
                onBack={handleBack}
                isPhone={isPhone}
                tourUUID={tour.id}
              />
            </motion.div>
          )}
          {step === 3 && (
            <motion.div
              key="step3"
              variants={pageVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={pageTransition}
              className="preScreen"
            >
              <ConfirmationScreen
                date={DateTime.fromMillis(selectedDate * 1000, {
                  zone: "Europe/Madrid",
                }).toISODate()}
                time={selectedTime}
                onBack={handleBack}
                formData={formData}
                setFormData={setFormData}
                secondsEpoch={selectedDate}
                isPhone={isPhone}
                tourDuration={tour.duration}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </header>
    </div>
  );
};

const WrappedReservationManager = () => (
  <AppointmentProvider>
    <ReservationManager />
  </AppointmentProvider>
);

export { AppointmentContext, WrappedReservationManager as default };
