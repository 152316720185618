// src/MainApp/pages/NotFound/NotFound.js
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NotFound.module.css";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  return (
    <div className={styles.notFoundContainer}>
      <h1 className={styles.title}>404 - {t("404.head")}</h1>
      <p className={styles.description}>{t("404.content")}</p>
      <button
        onClick={() => navigate("/")}
        className={styles.backButton}
        type="button"
      >
        {t("404.button")}
      </button>
    </div>
  );
};

export default NotFound;
