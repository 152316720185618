import React, { useCallback, useContext, useEffect, useState } from "react";
import "./SmoothSlider.css";
import { PCarousel } from "@porsche-design-system/components-react";
import { IsRotatedContext } from "MainApp/assets/utils/context/isRotatedContext";
import useIsPhone from "MainApp/assets/utils/hooks/useIsPhone";
import getCachedImage from "MainApp/assets/utils/js/getCachedImage";
import { SkeletonImagePreload } from "MainApp/assets/visual/styles/globalStyles/globalJsxStyles";

// Importamos las funciones de IndexedDB
import { storeImage, getImage } from "MainApp/assets/utils/js/indexedDbUtils";

const SmoothSlider = ({ images, imageLoaded, setImageLoaded }) => {
  const [formattedUrls, setFormattedUrls] = useState([]);
  const [cachedUrls, setCachedUrls] = useState([]);
  const [imageLoading, setImageLoading] = useState([]);
  const isPhone = useIsPhone();

  useEffect(() => {
    if (images && images.length > 0) {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const newUrls = images.map((url) => {
        if (url.startsWith("http://") || url.startsWith("https://")) {
          return url;
        }
        return `${baseUrl}${url}`;
      });
      setFormattedUrls(newUrls);
    }
  }, [images]);

  useEffect(() => {
    let isSubscribed = true;

    const loadImages = async () => {
      if (formattedUrls.length > 0) {
        const promises = formattedUrls.map(async (url, index) => {
          const imageId = `image-${index}-${url}`;
          let cachedUrl = null;

          if (isPhone) {
            // En dispositivos móviles, intentamos obtener la imagen de IndexedDB
            cachedUrl = await getImage(imageId);

            if (!cachedUrl) {
              // Si no está en IndexedDB, la almacenamos
              await storeImage(imageId, url);
              cachedUrl = await getImage(imageId);
            }
          } else {
            // En dispositivos no móviles, usamos la Cache API
            cachedUrl = await getCachedImage(url);
          }

          return cachedUrl;
        });

        const results = await Promise.all(promises);

        if (isSubscribed) {
          setCachedUrls(results.filter(Boolean)); // Filtra URLs no válidas
          setImageLoading(new Array(results.length).fill(true));
        }
      }
    };

    loadImages();

    return () => {
      isSubscribed = false;
    };
  }, [formattedUrls, isPhone]);

  const handleImageLoaded = useCallback(() => {
    if (!imageLoaded) {
      setTimeout(() => setImageLoaded(true), 0);
    }
  }, [imageLoaded, setImageLoaded]);

  const handleImageLoad = (index) => {
    setImageLoading((prevLoading) => {
      const newLoading = [...prevLoading];
      newLoading[index] = false;
      handleImageLoaded();
      return newLoading;
    });
  };

  const { isRotated } = useContext(IsRotatedContext);
  const rotatedStyle = {
    height: isPhone ? (isRotated ? "32.5svw" : "32.5svh") : "32.5svh",
  };

  //   useEffect(() => {
  //     return () => {
  //       // Revocar las URLs de objeto al desmontar el componente
  //       cachedUrls.forEach((url) => {
  //         URL.revokeObjectURL(url);
  //       });
  //     };
  //   }, [cachedUrls]);

  return (
    <PCarousel pagination={true} slidesPerPage="auto" width="extended">
      {cachedUrls.map((cachedImageUrl, index) => (
        <div className="newCard" key={index}>
          {cachedImageUrl ? (
            <img
              className="newCardImage"
              style={rotatedStyle}
              src={cachedImageUrl}
              alt={`image-${index}`}
              onLoad={() => handleImageLoad(index)}
              onError={() => handleImageLoad(index)}
            />
          ) : (
            <div className="imageError">Imagen no disponible</div>
          )}
        </div>
      ))}

      {/* Opcional: Añadir un div extra después del último elemento */}
      {cachedUrls.length > 0 && (
        <div className="extraDiv">{/* Contenido adicional */}</div>
      )}
    </PCarousel>
  );
};

export default SmoothSlider;
